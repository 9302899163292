* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
}
html {
  height: 100%;
  width: 100%;
}
body {
  min-height: 100%;
  min-width: 100%;
}

/* Breite der Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}

/* ::-webkit-scrollbar-track {
  background: red;
} */

/* Farbe des Scroll-Thumb */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Hover-Effekt für den Scroll-Thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  all: unset;
}

.is-current{
  font-weight: 900;
  color: #0E99F0;
}
input[type='checkbox']:checked {
  background-color: red;
}

.main-svg{
  background-color: rgba(0,0,0,0.2);
}
