
table {
  margin:1em auto;
}
td {
  height:30px;
  width:30px;
  border:1px solid;
  text-align:center;
}
td:first-child {
  border-left:3px solid;
}
td:nth-child(3n) {
  border-right: 3px solid ;
}
tr:first-child {
  border-top:3px solid;
}
tr:nth-child(3n) td {
  border-bottom:3px solid ;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}