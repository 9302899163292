:root { 
  
  --color1: rgb(130, 201, 17);
  --color2: rgb(21, 180, 47);
  --color3: rgb(20, 196, 137);
  --starColor: rgb(226, 230, 11);
 
  --size: 15px;
}

@keyframes background-pan {
  from {
    background-position: 0% ;
  }
  
  to {
    background-position: -200% ;
  }
}


@keyframes scale {
  from, to {
    transform: scale(0);
  }
  
  50% {
    transform: scale(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  
  to {
    transform: rotate(180deg);
  }
}

.magic {
  display: inline-block;
  position: relative;
}

.magic-star {
  /* --size: clamp(20px, 1.5vw, 30px); */
  
  animation: scale 700ms ease forwards;
  display: block;
  height: var(--size);
  left: var(--star-left);
  position: absolute;
  top: var(--star-top);
  width: var(--size);
}

.magic > .magic-star > svg {
  animation: rotate 1000ms linear infinite;
  display: block;
  opacity: 0.5;
}

.magic > .magic-star > svg > path {
  fill: var(--starColor);
}

.magic > .magic-text {
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  animation: background-pan 1.5s linear infinite;
  background: linear-gradient(
    to right,
    var(--color1),
    var(--color2),
    var(--color3),
    var(--color1)
  );
  background-size: 200%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}
